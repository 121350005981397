import React from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Table,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
} from "reactstrap";
import _ from "lodash";

import PromptModal from "../../../components/Indicator/Prompt";

const ModalReportDetails = (props) => {
  return (
    <Modal isOpen={props.isOpen} toggle={props.toggle} size={"xl"}>
      <ModalHeader toggle={props.toggle}>
        View current report details
        <Button
          color="success"
          className="ml-2"
          onClick={() => {
            props.oCReportHOC("showConfirmCloseReport", true);
          }}
        >
          Close current report
        </Button>
      </ModalHeader>
      <ModalBody>
        <Card className="mb-1">
          <CardHeader>Bank account details</CardHeader>
          <CardBody>
            <Table>
              <thead>
                <tr>
                  <th>Bank account number</th>
                  <th>Bank name</th>
                  <th>Start</th>
                  <th>End</th>
                  <th>Used</th>
                </tr>
              </thead>
              <tbody>
                {props.currentReport &&
                  props.currentReport.both_bank_account_detail &&
                  props.currentReport.both_bank_account_detail.map(
                    (item, index) => {
                      return (
                        <tr key={`Bank-both_bank_account_detail-${index}`}>
                          <td>{`${item.bank_account_name} - ${item.account_number}`}</td>
                          <td>{item.bank_name}</td>
                          <td>{item.start_balance}</td>
                          <td>{item.end_balance}</td>
                          <td>{item.used_balance}</td>
                        </tr>
                      );
                    }
                  )}
              </tbody>
            </Table>
          </CardBody>
          <div style={{ paddingRight: "15px" }}>
            <h5
              style={{ textAlign: "right" }}
            >{`Cash in: ${props.currentReport.total_cash_in}`}</h5>
            <h5
              style={{ textAlign: "right" }}
            >{`Cash out: ${props.currentReport.total_cash_out}`}</h5>
            <h5
              style={{ textAlign: "right" }}
            >{`Total: ${props.currentReport.total_bank_account_detail_balance}`}</h5>
          </div>
        </Card>
        <Card className="mb-1">
          <CardHeader>Game platform</CardHeader>
          <CardBody>
            <Table>
              <thead>
                <tr>
                  <th>Game</th>
                  <th>Start</th>
                  <th>End</th>
                  <th>Used</th>
                  <th>Kiosk in</th>
                  <th>Kiosk out</th>
                  <th>Burn</th>
                  <th>Tips</th>
                </tr>
              </thead>
              <tbody>
                {props.currentReport &&
                  props.currentReport.both_game_platform &&
                  props.currentReport.both_game_platform.map((item, index) => {
                    return (
                      <tr key={`Game-platform-${index}`}>
                        <td>{item.game_platform_name}</td>
                        <td>{item.start_balance}</td>
                        <td>{item.end_balance}</td>
                        <td>{item.used_balance}</td>
                        <td>{item.total_topup}</td>
                        <td>{item.total_refund}</td>
                        <td>{item.total_burn}</td>
                        <td>{item.total_tips}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </CardBody>
          <div style={{ paddingRight: "15px" }}>
            <h5
              style={{ textAlign: "right" }}
            >{`Total kiosk in: ${props.currentReport.total_report_topup}`}</h5>
            <h5
              style={{ textAlign: "right" }}
            >{`Total kiosk out: ${props.currentReport.total_report_refund}`}</h5>
            <h5
              style={{ textAlign: "right" }}
            >{`Total: ${props.currentReport.total_game_platform_balance}`}</h5>
          </div>
        </Card>
        <Card>
          <CardHeader>Promotions</CardHeader>
          <CardBody>
            <Table>
              <thead>
                <tr>
                  <th>Promo name</th>
                  <th>Bonus Amount</th>
                </tr>
              </thead>
              <tbody>
                {props.currentReport &&
                  props.currentReport.both_promotion &&
                  props.currentReport.both_promotion.promotion.map(
                    (item, index) => {
                      return (
                        <tr key={`Promo-${index}`}>
                          <td>{item.promotion_name}</td>
                          <td>{item.bonus_amount}</td>
                        </tr>
                      );
                    }
                  )}
              </tbody>
            </Table>
          </CardBody>
          <div style={{ paddingRight: "15px" }}>
            <h5
              style={{ textAlign: "right" }}
            >{`Total: ${props.currentReport.total_promotion_balance}`}</h5>
          </div>
        </Card>
        <Card>
          <CardBody>
            <h4
              style={{ textAlign: "right" }}
            >{`Total final balance: ${props.currentReport.total_final_balance}`}</h4>
          </CardBody>
        </Card>
        <PromptModal
          showPromptModal={props.showConfirmCloseReport}
          onClickYes={() => {
            props.closeCurrentReport();
          }}
          onClickNo={() => {
            props.oCReportHOC("showConfirmCloseReport", false);
          }}
          content={"Are you sure to close the current report?"}
        />
      </ModalBody>
    </Modal>
  );
};

export default ModalReportDetails;
